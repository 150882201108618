import React, { Component } from 'react';
import { SearchBar } from '../NavMenu/SearchBar';

import ShortTransferWebpart from '../DynamicPages/Webparts/ShortTransferWebpart'

export class Home extends Component {
    static displayName = Home.name;
    state = {
        pageContentData: []
    }


    render() {
        return (
            <div>
                <section className="home-slider owl-carousel">
                    <div className="slider-item" id="bg_1">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Experience the best trip ever</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item" id="bg_2">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">A bad day on the slopes beats a good day at work</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item" id="bg_3">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">My life is going downhill</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item" id="bg_4">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Ski good or eat wood</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item" id="bg_5">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Skiing is expensive, but it's cheaper than therapy</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item" id="bg_6">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Turn right. Turn left. Repeat as necessary</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="slider-item" id="bg_7">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">You can't buy happiness, but you can buy a ski pass</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="slider-item" id="bg_8">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Wax 'em up!</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="slider-item" id="bg_9">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">The mountains are calling and I must go</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="slider-item" id="bg_10">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">When life gets complicated, I go skiing (or snowboarding)</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="slider-item" id="bg_11">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Skiing is more than a sport, it is a way of life</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="slider-item" id="bg_12">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Skiing is a dance, and the mountain always leads</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="slider-item" id="bg_13">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">A bad day on the slopes beats a good day at work</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SearchBar location={this.props.location} />

                <ShortTransferWebpart />










                    <div className="container-fluid">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-md-7 text-center heading-section">
                                <div class="popular-destinations-section">
                                    <h2>Explore Our Most Popular Destinations:</h2>
                                    <p>Discover the top ski destinations that our customers love. From breathtaking alpine views to world-class slopes, these popular spots offer unforgettable experiences. Check out our most sought-after destinations below!</p>
                                </div>

                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-6 col-lg-3">
                                <a href="/Austria/Alpbach" className="block-5" id="tour_1">
                                    <div className="text">
                                        <h3 className="heading">Alpbach</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <a href="/Austria/Flachau" className="block-5" id="tour_2">
                                    <div className="text">
                                        <h3 className="heading">Flachau</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <a href="/France/Flaine" className="block-5" id="tour_3">
                                    <div className="text">
                                        <h3 className="heading">Flaine</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <a href="/France/Tignes" className="block-5" id="tour_4">
                                    <div className="text">
                                        <h3 className="heading">Tour Tignes</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <a href="/Switzerland/Verbier" className="block-5" id="tour_5">
                                    <div className="text">
                                        <h3 className="heading">Verbier</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <a href="/Austria/St.%20Anton%20am%20Arlberg" className="block-5" id="tour_6">
                                    <div className="text">
                                        <h3 className="heading">St Anton</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <a href="/Italy/Courmayeur" className="block-5" id="tour_7">
                                    <div className="text">
                                        <h3 className="heading">Courmayeur</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <a href="/France/Chamonix" className="block-5" id="tour_8">
                                    <div className="text">
                                        <h3 className="heading">Chamonix</h3>
                                        <div className="post-meta">
                                            <span></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>



            </div >

        );
    }
}
