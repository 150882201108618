import React from 'react';
import FooterLinks from'./FooterLinks'

const Footer = (props) => {
    return (
        <div>
            <footer className="ftco-footer ftco-bg-dark ftco-section">
                <div className="container">
                    <div className="row mb-5">
                        <FooterLinks />
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="ftco-footer-widget mb-3">
                                <h2 className="ftco-heading-2">ChaletAway</h2>
                                <p>Here at ChaletAway, our aim is to help you to spend more time on the slopes in the places that suit you. Whether it’s a resort with great apréski or a short transfer time you’re looking for, let us guide you in finding your perfect holiday.</p>
                                <a href="/Privacy" className="py-2 d-block">Privacy Policy</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ftco-footer-widget mb-3">
                                <h2 className="ftco-heading-2">Contact Information</h2>
                                <ul className="list-unstyled">
                                    <li><a href="mailto:Hello@ChaletAway.co.uk" className="py-2 d-block" >Hello@ChaletAway.co.uk</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ftco-footer-widget mb-3">
                                <ul className="ftco-footer-social list-unstyled float-md-right float-lft">
                                    <li key="fle" className="ftco-animate"><a href="mailto:Hello@ChaletAway.co.uk" target="_blank" rel="noreferrer"> <i key="flei" className="fas fa-at fa-2x"></i></a></li>
                                    <li key="flt" className="ftco-animate"><a href="https://twitter.com/chaletaway" target="_blank" rel="noreferrer"> <i key="flti" className="fab fa-twitter fa-2x"></i></a></li>
                                    <li key="fli" className="ftco-animate"><a href="https://www.instagram.com/chaletaway/" target="_blank" rel="noreferrer"><i key="flii" className="fab fa-instagram fa-2x"></i></a></li>
                                    <li key="flf" className="ftco-animate"><a href="https://www.facebook.com/ChaletAway-108945474210504/" target="_blank" rel="noreferrer"><i key="flfi"  className="fab fa-facebook-f fa-2x"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-12 text-center">

                            <p>
                                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved ChaletAway
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            <div>
                <div id="ftco-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee" /><circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#F96D00" /></svg></div>
            </div>
        </div>


    );
}

export default Footer;

