import React, { useState, useEffect } from 'react';
import ChaletResultsWebpart from './ChaletResultsWebpart';

const ShortTransferWebpart = () => {
    const [chalets, setChalets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/SearchByInspireMeAttributeLite/1`);
                const result = await response.json();
                setChalets(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;


    return (
        <div>

            <div className="row no-gutters justify-content-center pt-5">
                <div className="col-md-7 text-center heading-section">
                    <div class="short-transfer-section">
                        <h2>Looking for Accommodation with a Short Transfer? Try These:</h2>
                        <p>Experience the ultimate convenience with our selection of ski chalets offering short transfers from the airport. Perfect for maximizing your time on the slopes, these chalets ensure a smooth and quick journey. Check out our top recommendations below!</p>
                    </div>

                </div>
            </div>




         

                <div className="row">
                    {chalets.length > 0 ? (
                        chalets.map(chalet => (
                            <div key={chalet.chaletId} className="col-12 col-xl-4 col-lg-6 mb-4 ftco-animate fadeInUp ftco-animated">
                                <a href={chalet.internalUrl} className="block-5" target="_blank" rel="noreferrer" style={{ backgroundImage: `url(${chalet.thumbnailImageUrl})` }}>
                                    <div className="text">
                                        <h3 className="heading">{chalet.name}</h3>
                                        <div className="post-meta">
                                            <span>
                                                {chalet.currencySymbol}
                                                {chalet.totalQueryCost.toLocaleString('en-GB', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                {' '}for 7 days
                                            </span>
                                            <br />
                                            <span>{chalet.resort.name}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))
                    ) : (
                        <p>No chalets available</p>
                    )}
                </div>
        </div>
    );
};

export default ShortTransferWebpart;
//<div className="col-12 col-lg-9 col-xl-10">
//    <div className="row">
//        <ChaletResultsWebpart chalets={chalets} />
//    </div>
//</div>