import React, { useState, useEffect } from 'react';
import { SearchBar } from '../NavMenu/SearchBar';

const Header = ({ PageBreadCrumbName, PageName }) => {
    const [backgroundId, setBackgroundId] = useState('');

    useEffect(() => {
        const min = 1;
        const max = 13;
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        setBackgroundId(`bg_${number}`);
    }, []);

    return (
        <div>
            <section className="header-static-container">
                <div className="static-image" id={backgroundId} data-stellar-background-ratio="0.5">
                    <div className="container">
                        <div className="row header-text align-items-center">
                            <div className="col-md-7 col-sm-12">
                                <p className="breadcrumbs">
                                    <span className="mr-2">
                                        <a href="/">Home</a>
                                    </span>
                                    <span>{PageBreadCrumbName}</span>
                                </p>
                                <h1 className="mb-3">{PageName}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SearchBar toggleResultVisability="true" />
        </div>
    );
};

export default Header;
