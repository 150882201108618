import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const apiUrl = process.env.REACT_APP_API_URL;

const colourStyles = {
    control: styles => ({
        ...styles,
        backgroundColor: '#ff5f5f',
        border: 'none',
    }),
    option: (styles, { data }) => ({
        ...styles,
        fontWeight: data.fontWeight,
        height: '52px',
    }),
};

const OriginAirport = ({ setOriginAirports, setsearchButtonDisabled }) => {
    const [navMenu, setNavMenu] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        // Fetch data and populate navMenu state
        const getItems = async () => {
            try {
                const res = await fetch(apiUrl + '/NavMenuOriginAirports');
                const data = await res.json();
                const dataList = data.map(airportData => ({
                    label: airportData.airportName,
                    airportCode: airportData.airportCode,
                    value: airportData.airportCode
                }));


                setNavMenu(dataList);
            } catch (error) {
                console.log(error);
            }
        };

        getItems();
    }, []);

    const handleChange = selectedOption => {
        setSelectedValue(selectedOption.airportCode);
        setOriginAirports(prevAirports => {
            // Check if already included, then remove, otherwise add
            if (prevAirports.includes(selectedOption.airportCode)) {
                return prevAirports.filter(code => code !== selectedOption.airportCode);
            } else {
                return [...prevAirports, selectedOption.airportCode];
            }
        });
    }







    return (
        <Select
            components={animatedComponents}
            options={navMenu}
            styles={colourStyles}
            onChange={handleChange}
            value={navMenu.filter(({ value }) => value === selectedValue)}
            className="searchbarDropDown"
            placeholder="From"
            classNamePrefix="searchBarDDL"
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary: '#ff5f5f',
                    primary75: '#FF8383',
                    primary50: '#FF8383',
                    primary25: '#FF8383',
                },
            })}
        />
    );
};

export default OriginAirport;
