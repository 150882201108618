import React, { useState } from 'react';
import DestinationSearchArea from '../DestinationSearchArea';
import 'react-calendar/dist/Calendar.css';
import NumberController from '../../ContentParts/NumberController';
import ChaletAwayDatePicker from './ChaletAwayDatePicker';
import QueryStringBuilder from '../../querystring.js';
import moment from 'moment';
import 'moment/locale/en-gb'; // Import the locale you need


const ChaletSearchBar = ({ getClassName, toggleResultVisability }) => {
    const [state, setState] = useState({
        searchQuery: {
            type: "Chalet",
            CountryId: 0,
            ResortIds: [],
            //DateFrom: '',
            //DateTo: '',
            Adults: 2,
            Rooms: 1,
            Children: 0,
            CountrySearchArray: []
        },
        adultCount: 2,
        childCount: 0,
        roomCount: 1,
        date: [],
        dateFromTempVisible: true,
        dateToTempVisible: true,
        roomCountTempVisible: true,
        adultCountTempVisible: true,
        childCountTempVisible: true,
        allQuerystringParams: null,
        filtersApplied: null,
    });

    const [searchButtonDisabled, setsearchButtonDisabled] = useState(true);

    const handleQueryStringData = (queryStringParams) => {
        if (state.allQuerystringParams === null) {
            setState(prevState => ({ ...prevState, allQuerystringParams: queryStringParams }));
            applyFiltersIfAvailableToSearchBox(queryStringParams);
        }
    };

    const applyFiltersIfAvailableToSearchBox = (allQuerystringSearchQueryObject) => {
        if (!allQuerystringSearchQueryObject.queryStringNull) {
            if (state.filtersApplied !== true) {
                roomsClick();
                adultsClick();
                childClick();

                const dateReplace = [allQuerystringSearchQueryObject.DateFrom, allQuerystringSearchQueryObject.DateTo];

                setState(prevState => ({
                    ...prevState,
                    filtersApplied: true,
                    searchQuery: allQuerystringSearchQueryObject,
                    date: dateReplace,
                    adultCount: allQuerystringSearchQueryObject.Adults,
                    childCount: allQuerystringSearchQueryObject.Children,
                    roomCount: allQuerystringSearchQueryObject.Rooms,
                    allQuerystringParams: allQuerystringSearchQueryObject
                }));
            }
        }
    };

    // Regular expressions for different date formats
    const ddmmyyyyRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/;

    // Function to determine date format and parse accordingly
    const parseDate = (dateStr) => {
        if (ddmmyyyyRegex.test(dateStr)) {
            const [day, month, year] = dateStr.split("/");
            return new Date(`${year}-${month}-${day}`);
        } else if (yyyymmddRegex.test(dateStr)) {
            return new Date(dateStr);
        } else {
            throw new Error("Unknown date format");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let qs = "/Findmeachalet?";

        if (Array.isArray(state.searchQuery.CountrySearchArray) &&
            state.searchQuery.CountrySearchArray.length > 0 &&
            state.searchQuery.CountrySearchArray.some(id => id !== 0)) {
            qs = buildQueryStringArray(qs, 'CountrySearchArray', state.searchQuery.CountrySearchArray);
        } else {
            if (Array.isArray(state.searchQuery.ResortIds) && state.searchQuery.ResortIds.length > 0) {
                var resortIds = state.searchQuery.ResortIds.join(',')
                qs = buildQueryString(qs, 'ResortIds', resortIds);
            }
        }

        const today = new Date();
        const defaultDateFrom = new Date(today);
        defaultDateFrom.setDate(today.getDate() + 30);
        const defaultDateTo = new Date(today);
        defaultDateTo.setDate(today.getDate() + 37);

        const [selectedStartDate, selectedEndDate] = state.date;
        console.log("selectedStartDate")
        console.log(selectedStartDate)

       

        const dateFrom = selectedStartDate ? parseDate(selectedStartDate) : defaultDateFrom;
        const dateTo = selectedEndDate ? parseDate(selectedEndDate) : defaultDateTo;

        qs = buildQueryString(qs, 'DateFrom', dateFrom.toJSON().slice(0, 10));
        qs = buildQueryString(qs, 'DateTo', dateTo.toJSON().slice(0, 10));
        qs = buildQueryString(qs, 'Adults', state.adultCount);
        qs = buildQueryString(qs, 'Children', state.childCount);
        qs = buildQueryString(qs, 'Rooms', state.roomCount);

        window.location.href = qs;
    };

    const buildQueryString = (qs, prop, value) => {
        const lastChar = qs.charAt(qs.length - 1);
        if (lastChar === "?") {
            return `${qs}${prop}=${value}`;
        } else {
            return `${qs}&${prop}=${value}`;
        }
    };

    const buildQueryStringArray = (qs, prop, value) => {
        if (!Array.isArray(value)) {
            value = [value];
        }
        const combinedValues = value.join(',');
        const lastChar = qs.charAt(qs.length - 1);
        const separator = lastChar === "?" || lastChar === "" ? "" : "&";
        qs += `${separator}${prop}=${combinedValues}`;
        return qs;
    };

    const updateDate = (date) => {
        setState(prevState => ({ ...prevState, date }));
    };

    const updateAdultCountAdd = () => {
        setState(prevState => ({ ...prevState, adultCount: prevState.adultCount + 1 }));
    };

    const updateAdultCountDecrease = () => {
        if (state.adultCount > 1) {
            setState(prevState => ({ ...prevState, adultCount: prevState.adultCount - 1 }));
        }
    };

    const updateChildCountAdd = () => {
        setState(prevState => ({ ...prevState, childCount: prevState.childCount + 1 }));
    };

    const updateChildCountDecrease = () => {
        if (state.childCount > 0) {
            setState(prevState => ({ ...prevState, childCount: prevState.childCount - 1 }));
        }
    };

    const updateRoomCountAdd = () => {
        setState(prevState => ({ ...prevState, roomCount: prevState.roomCount + 1 }));
    };

    const updateRoomCountDecrease = () => {
        if (state.roomCount > 1) {
            setState(prevState => ({ ...prevState, roomCount: prevState.roomCount - 1 }));
        }
    };

    const roomsClick = () => {
        setState(prevState => ({ ...prevState, roomCountTempVisible: false }));
    };

    const adultsClick = () => {
        setState(prevState => ({ ...prevState, adultCountTempVisible: false }));
    };

    const childClick = () => {
        setState(prevState => ({ ...prevState, childCountTempVisible: false }));
    };

    const handleSearchQueryChange = (newQuery) => {
        setState(prevState => ({
            ...prevState,
            searchQuery: {
                ...prevState.searchQuery,
                ...newQuery
            }
        }));
    };

    return (
        <div className={getClassName} id="chaletSearch" role="tabpanel" aria-labelledby="chaletSearch">
            <QueryStringBuilder sendDataToParent={handleQueryStringData} />
            <div className="block-17">
                <form action="" method="post" className="d-block d-lg-flex" onSubmit={handleSubmit}>
                    <div className="fields">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-sm-12 mt-3">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-6 col-sm-12 mt-3">
                                            <DestinationSearchArea
                                                searchQuery={state.searchQuery}
                                                setsearchButtonDisabled={setsearchButtonDisabled}
                                                onSearchQueryChange={handleSearchQueryChange}
                                            />


                                    </div>
                                    <div className="col-xl-8 col-lg-6 col-sm-12 mt-3">
                                            <ChaletAwayDatePicker updateDate={updateDate} selectedDate={state.date} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-sm-12 mt-3">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-sm-12 mt-3">


                                        <div id="container" className={state.roomCountTempVisible ? "" : "noselect white-border"}>
                                            <input type="text" data-lpignore="true" className={state.roomCountTempVisible ? "form-control" : "hideMe"} onClick={roomsClick} placeholder="Rooms" />
                                            <div className={state.roomCountTempVisible ? "hideMe" : "qty row"}>
                                                <div className="col-1 pr-lg-2">
                                                    <i className="fas fa-bed"></i>
                                                </div>
                                                <div className="col-10">
                                                    <NumberController id="RoomNumController" IncreaseCount={updateRoomCountAdd} currentCount={state.roomCount} DecreaseCount={updateRoomCountDecrease} className="numbersCounter-coral" />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12  mt-3">
                                        <div id="container" className={state.adultCountTempVisible ? "" : "noselect white-border"}>
                                            <input type="text" data-lpignore="true" className={state.adultCountTempVisible ? "form-control" : "hideMe"} onClick={() => adultsClick()} placeholder="Adults" />
                                            <div className={state.adultCountTempVisible ? "hideMe" : "qty row"}>
                                                <div className="col-1 pr-lg-2">
                                                    <i className="fas fa-user"></i>
                                                </div>
                                                <div className="col-10">
                                                    <NumberController id="AdultNumController" IncreaseCount={updateAdultCountAdd} currentCount={state.adultCount} DecreaseCount={updateAdultCountDecrease} className="numbersCounter-coral" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12  mt-3">
                                        <div id="container" className={state.childCountTempVisible ? "" : "noselect white-border"}>
                                            <input type="text" data-lpignore="true" className={state.childCountTempVisible ? "form-control" : "hideMe"} onClick={() => childClick()} placeholder="Children" />
                                            <div className={state.childCountTempVisible ? "hideMe" : "qty row"}>
                                                <div className="col-1 pr-lg-2">
                                                    <i className="fas fa-child"></i>
                                                </div>
                                                <div className="col-10">
                                                    <NumberController id="ChildNumController" IncreaseCount={updateChildCountAdd} currentCount={state.childCount} DecreaseCount={updateChildCountDecrease} className="numbersCounter-coral" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-12 mt-3">
                                        <input type="submit" disabled={searchButtonDisabled} className="search-submit btn btn-primary" value="Find me a Chalet" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChaletSearchBar;
