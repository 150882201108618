import React, { Component } from 'react';
import ChaletSearchBar from './Searchbar/ChaletSearchBar';
import FlightSearchBar from './Searchbar/FlightSearchBar';
import InspireSearchBar from './Searchbar/InspireSearchBar';

export class SearchBar extends Component {
    state = {
        pageContentData: [],
        countryId: 0,
        ResortData: {
            ResortDisabled: true,
            countryId: -1
        },
        startDate: new Date(),
        endDate: new Date()
    }

    render() {
        return (
            <div className="ftco-section-search" id="ca-ftco-section-search">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tabulation-search">
                            <div className="element-animate">
                                <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    {/*<a className="nav-link p-3 active" id="v-pills-home-tab" data-bs-toggle="pill" href="#chaletSearch" role="tab" aria-controls="chaletSearch" aria-selected="true">Chalet</a>*/}
                                    {/*<a className="nav-link p-3" id="v-pills-inspire-tab" data-bs-toggle="pill" href="#inspireSearch" role="tab" aria-controls="inspireSearch" aria-selected="true">Inspire Me</a>*/}
                                    {/*<a className="nav-link p-3" id="v-pills-flight-tab" data-bs-toggle="pill" href="#flightSearch" role="tab" aria-controls="flightSearch" aria-selected="false">Flights</a>*/}
                                </div>
                            </div>

                            <div className="tab-content py-5" id="tabContent">
                                <div className="tab-pane fade show active" id="chaletSearch" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <ChaletSearchBar toggleResultVisibility={this.props.toggleResultVisibility} location={this.props.location} />
                                </div>

                                {/*<div className="tab-pane fade" id="inspireSearch" role="tabpanel" aria-labelledby="v-pills-inspire-tab">*/}
                                {/*    <InspireSearchBar toggleResultVisibility={this.props.toggleResultVisibility} location={this.props.location} />*/}
                                {/*</div>*/}



                                {/*<div className="tab-pane fade" id="flightSearch" role="tabpanel" aria-labelledby="v-pills-flight-tab">*/}
                                {/*    */}{/*<FlightSearchBar toggleResultVisibility={this.props.toggleResultVisibility} location={this.props.location} />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


//<div className="tab-content py-5" id="v-pills-tabContent">

//    <InspireSearch location={this.props.location} />
//</div>



//<ChaletSearch toggleResultVisability={this.props.toggleResultVisability} location={this.props.location} />
//    <InspireSearch location={this.props.location} />




////<div className="element-animate">
////    <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
////        <a className="nav-link p-3 active" id="v-pills-home-tab" data-toggle="pill" href="#chaletSearch" role="tab" aria-controls="chaletSearch" aria-selected="true">Chalet</a>
////        {/*<a className="nav-link p-3" id="v-pills-profile-tab" data-toggle="pill" href="#inspireMe" role="tab" aria-controls="chaletflySearch" aria-selected="false">Chalet Fly</a>*/}
////        {/*<a className="nav-link p-3" id="v-pills-settings-tab" data-toggle="pill" href="#Transfer" role="tab" aria-controls="chaletDriveSearch" aria-selected="false">Chalet Drive</a>*/}
////        {/*<a className="nav-link p-3" id="v-pills-profile-tab" data-toggle="pill" href="#inspireMe" role="tab" aria-controls="chaletTrainSearch" aria-selected="false">Chalet Train</a>*/}
////        <a className="nav-link p-3" id="v-pills-profile-tab" data-toggle="pill" href="#flightSearch" role="tab" aria-controls="flight" aria-selected="false">Flights</a>
////        {/*<a className="nav-link p-3" id="v-pills-settings-tab" data-toggle="pill" href="#Transfer" role="tab" aria-controls="Transfer" aria-selected="false">Transfer</a>*/}
////        {/*<a className="nav-link p-3" id="v-pills-settings-tab" data-toggle="pill" href="#Transfer" role="tab" aria-controls="InspireMe" aria-selected="false">Inspire Me</a>*/}
////    </div>
////</div>
